import React, { useEffect, useState } from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import { goto } from "../_utils/Misc";
import Qr from "../_utils/Qr";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";

const RewardLander = () => {
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/");
        }
    }, []);

    return (
        <Layout title="Welcome">
            <MainTitle>
                Congratulations you have won!
            </MainTitle>

            <PageSummary>
                Just hit 'Claim Gift' for your prize and remember to keep scanning to win more!
            </PageSummary>

            <ClaimRewardBtn />

            <Banner
                location="/registration/"
                image="https://assets.xela.co/silicon-valley-classic/svm/web/d4bdd265-183e-495e-b52e-cef44f3554ea.jpg"
                text="A GIFT BASKET FROM OUR PARTNERS"
            />

            <Banner
                location="/registration/"
                image="https://assets.xela.co/silicon-valley-classic/svm/web/a4c6e0a6-453f-457d-8388-a35446faab57.jpg"
                text="TENNIS CHANNEL MERCHANDISE"
            />

            <Banner
                location="/registration/"
                image="https://assets.xela.co/silicon-valley-classic/svm/web/09f8df39-11c6-4fcf-a721-3650df6efd00.jpg"
                text="DISCOUNTS AT THE TOURNAMENT STORE"
            />

            {/* <RewardsCarousel></RewardsCarousel> */}

            <ClaimRewardBtn />
        </Layout>
    );
};

export default RewardLander;
